import React from "react";

const Home = ({ topLine, headline, description, img }) => (
  <div className="home-section h-card">
    <div>
      <span className="p-name">{topLine}</span>
      <h1 className="p-organization">{headline}</h1>
      <p className="p-description">{description}</p>
    </div>
    <div className="image" style={{ backgroundImage: `url(${img})` }}></div>
  </div>
);

export default Home;
