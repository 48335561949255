import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function Consultation({ h2, description, btnText }) {
  return (
    <>
      <div className="consultation-section">
        <h2>{h2}</h2>
        <p>{description}</p>
        <Link
          className="btn"
          to="/contact"
          onClick={scrollToTop}
          target="_blank"
          rel="noopener noreferrer"
        >
          {btnText} <FaArrowRight />
        </Link>
      </div>
    </>
  );
}

export default Consultation;
