import React from "react";

function Review({ description, img, name, company }) {
  return (
    <div className="review">
      <div className="vector"></div>
      <p>{description}</p>
      <div className="row">
        <div className="image" style={{ backgroundImage: `url(${img})` }}></div>
        <div className="column">
          <p className="name">{name}</p>
          <p>{company}</p>
        </div>
      </div>
    </div>
  );
}

export default Review;
