import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function Footer() {
  return (
    <footer>
      <div className="row">
        <div className="menu">
          <h3>Menu</h3>
          <Link to="/" onClick={scrollToTop}>
            Home
          </Link>
          <Link to="/about" onClick={scrollToTop}>
            About
          </Link>
          <Link to="/projects" onClick={scrollToTop}>
            Projects
          </Link>
          <Link to="/contact" onClick={scrollToTop}>
            Contact
          </Link>
        </div>
        <div className="socials">
          <h3>Socials</h3>
          <div className="links">
            <Link
              className="social-icon-link"
              to="https://www.facebook.com/sanojdb"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            <Link
              className="social-icon-link"
              to="https://www.instagram.com/studio_sanoj/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to="https://www.linkedin.com/company/studio-sanoj/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </div>
      <p className="border"></p>
      <div className="column">
        <Link to="/">
          <div className="logo"></div>
        </Link>
        <p className="reference">
          © 2024 by{" "}
          <Link
            to="https://jonasdebruyne.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jonas De Bruyne
          </Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
