// FAQ.js
import React from "react";
import Question from "../components/Question";

const FAQ = ({ h2, faqData }) => {
  const filteredData = Object.fromEntries(
    Object.entries(faqData).filter(([name]) => name !== "h2")
  );

  return (
    <div className="FAQ-section">
      <h2>{h2}</h2>
      <div className="FAQ">
        {Object.entries(filteredData).map(
          ([key, { question, answer }], index) => (
            <Question key={index} question={question} answer={answer} />
          )
        )}
      </div>
    </div>
  );
};

export default FAQ;
