// Reviews.js
import React, { useState } from "react";
import Review from "../components/Review";
import Bullet from "../components/Bullet";

function Reviews({ h2, reviews = {} }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const filteredReviews = Object.fromEntries(
    Object.entries(reviews).filter(([reviewKey]) => reviewKey !== "h2")
  );

  const firstReviewKey = Object.keys(filteredReviews)[0];
  const firstReview = filteredReviews[firstReviewKey];

  const handleBulletClick = (index) => {
    setActiveIndex((prevIndex) => {
      // Remove "active" class from the previously active bullet
      const bullets = document.querySelectorAll(".bullets .bullet");
      bullets[prevIndex]?.classList.remove("active");

      // Add "active" class to the clicked bullet
      bullets[index]?.classList.add("active");

      // Set the active index for state management
      return index;
    });
  };

  return (
    <div className="reviews-section">
      <h2>{h2}</h2>
      <div className="reviews">
        {Object.entries(filteredReviews).map(([reviewKey, review], index) => (
          <div
            key={reviewKey}
            style={{ display: index === activeIndex ? "block" : "none" }}
          >
            <Review
              name={review.name}
              company={review.company}
              description={review.description}
              img={review.img}
            />
          </div>
        ))}
      </div>
      <div className="bullets">
        {[...Array(firstReview.totalBullets || 3)].map((_, index) => (
          <Bullet
            key={index}
            isActive={index === activeIndex}
            onClick={() => handleBulletClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default Reviews;
