import React from "react";
import Case from "../components/Case";

const Cases = ({ h2, projects }) => {
  // Take only the first 4 cases
  const firstFourCases = Object.entries(projects)
    .filter(([name]) => name !== "h2")
    .slice(0, 4);

  return (
    <div className="cases-section">
      <h2>{h2}</h2>
      <div className="cases">
        {firstFourCases.map(([caseKey, caseData]) => (
          <Case
            key={caseKey}
            name={caseData.name}
            description={caseData.description}
            image={caseData.image}
            skills={caseData.skills}
          />
        ))}
      </div>
    </div>
  );
};

export default Cases;
