export const welcome = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Doeltreffend Digitaliseren met Creatief Webdesign",
  headline: "STUDIO SANOJ - De ideale partner voor artiesten",
  description:
    "Een gloednieuwe website of een gebruiksvriendelijke applicatie voor jouw artiest? Reken op STUDIO SANOJ uit Sint-Niklaas",
  img: `${process.env.PUBLIC_URL}/assets/images/headerImage/mockupsDevicesMetejoor.webp`,
};

export const about = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Over mij",
  headline: "Hey, mijn naam is Jonas De Bruyne - Creatieve webdesigner",
  description:
    "Als webdesigner met een passie voor creatief ontwerp, bied ik strategieën die gericht zijn op artiesten. In 2022 ben ik begonnen met de opleiding 'Digital Experience Design' aan de Thomas More hogeschool in Mechelen, waar ik mijn vaardigheden in ontwikkeling en webdesign verder heb versterkt.",
  img: `${process.env.PUBLIC_URL}/assets/images/about/about.webp`,
};

export const partners = {
  h2: "Met trots werk ik samen met Creatieve Artiesten",
  metejoor: {
    image: "assets/images/partners/Metejoor.webp",
  },
  oneBookings: {
    image: "assets/images/partners/OneBookings.webp",
  },
  SNapback: {
    image: "assets/images/partners/5napback.webp",
  },
  LEEZ: {
    image: "assets/images/partners/Leez.webp",
  },
  fruitylabel: {
    image: "assets/images/partners/fruitylabel.svg",
  },
  Manuals: {
    image: "assets/images/partners/Manuals.webp",
  },
};

export const stats = {
  stats1: {
    name: "Jaren ervaring in Webdesign",
    amount: "1",
    icon: "",
  },
  stats2: {
    name: "Bezoekers website voor Artiesten",
    amount: "680",
    icon: "",
  },
  stats3: {
    name: "Afgewerkte projecten in Creatieve Ontwikkeling",
    amount: "5",
    icon: "",
  },
};

export const projects = {
  h2: "Deze klanten gingen jou voor",
  case1: {
    name: "Metejoor",
    intro: "Webdesign voor de band Metejoor.",
    description:
      "Metejoor, tot twee jaar geleden nog de enthousiaste leerkracht Joris Van Rossem, is ontzettend populair en al enkele jaren niet meer weg te denken uit de hitlijsten. Met hits als ‘1 Op Een Miljoen’, ‘Rendez-vous’, ‘Dit Is Wat Mijn Mama Zei’ en ‘Laat ons een bloem’ is hij bezig aan een indrukwekkende carrière.",
    goal: "Metejoor wou graag digitaal sterker in de picture komen door een vernieuwde website die zowel eenvoudig als flexibel aanpasbaar is.",
    result:
      "Een heldere website waarop alle verschillende elementen van Metejoor aan bod komen. We zorgen ervoor dat de nieuwssectie regelmatig wordt bijgewerkt, zodat fans snel op de hoogte worden gebracht van nieuwe releases en concerten. Bovendien zijn we verheugd te melden dat de clubtour van Metejoor in België volledig is uitverkocht, wat de groeiende populariteit van de band onderstreept.",
    image: "assets/images/cases/mockupMetejoor.webp",
    image2: "assets/images/reviews/Metejoor.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript", "PHP"],
    link: "https://metejoor.be/",
  },
  case2: {
    name: "5napback",
    intro: "Webdesign voor 5napback.",
    description:
      "Met 8 jaar ervaring en als winnaar van MNM Start to DJ is hij een gevestigde naam in de muziekwereld muziekscene. Met 4 succesvolle MNM Big Hits en meer dan 9 miljoen streams betovert hij luisterend Vlaanderen. Hij schittert ook op festivals als Tomorrowland en Balaton en als jongste lid van de 3-delige Bromeo's.",
    goal: "5napback had als doel een vernieuwde website te ontwikkelen die zowel gebruiksvriendelijk als aanpasbaar is, met als doel zijn online aanwezigheid te versterken en een groter publiek te bereiken.",
    result:
      "Sinds de lancering van de nieuwe website heeft 5napback een aanzienlijke toename in boekingsaanvragen ervaren, wat aantoont dat mijn werk impact heeft gehad en heeft bijgedragen aan zijn succes.",
    image: "assets/images/cases/mockup5napback.webp",
    image2: "assets/images/cases/5napback2.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript"],
    link: "https://5napback.com/",
  },
  case3: {
    name: "llllfour",
    intro: "Webdesign voor llllfour.",
    description:
      "Femke, Gloria, Lina en Marie-Emilie dromen van een carrière als zangeres. Ze maakten indruk tijdens 'The Voice Kids', nu vormen ze samen IIII, een nieuwe meidengroep die hedendaagse popmuziek brengt met invloeden van hun favoriete artiesten.",
    goal: "Hun website had als doel hun opkomende meidengroep te promoten en mensen meer te laten ontdekken over wie ze zijn.",
    result:
      "Mensen vinden snel de weg naar hun website en hun zomer is al druk met optredens door heel Vlaanderen.",
    image: "assets/images/cases/mockupllllfour.webp",
    image2: "assets/images/cases/llllfour.jpeg",
    skills: ["Figma", "HTML", "CSS", "Javascript"],
    link: "https://llllfour.be/",
  },
  case4: {
    name: "LEEZ",
    intro: "Webdesign voor Leez.",
    description:
      "Lisa Van Rossem, de zus en backing vocal van zanger Metejoor. Onlangs pakte ze uit met haar eerste solosingle “Who will be there”, nu is het tijd om haar gloednieuwe website voor te stellen.",
    goal: "Lisa streefde ernaar met haar website een centrale hub te creëren waar ze haar reels, video's en optredens kon delen, waardoor haar fans een diepere verbinding konden voelen met haar werk.",
    result:
      "Lisa maakt wekelijks gebruik van haar website om haar reels online te delen en is uitermate tevreden over het aanpasbare ontwerp.",
    image: "assets/images/cases/mockupLEEZ.webp",
    image2: "assets/images/reviews/LEEZ.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript", "PHP"],
    link: "https://leezofficial.be/",
  },
  case5: {
    name: "Manuals",
    intro: "Webdesign voor Manuals.",
    description:
      "Manuals is een dynamische EDM-producer en DJ die optreedt op grote festivals en clubs wereldwijd, met muziek die ook op nationale radiostations te beluisteren is.",
    goal: "Het doel van Manuals' website is om zijn fans en nieuwe luisteraars te voorzien van informatie over zijn muziek, optredens, en recente releases.",
    result:
      "Op zijn website en sociale media gebruikt Manuals altijd dezelfde stijl, kleuren en logo's. Zo weten mensen meteen dat het van hem is. Dat maakt zijn merk sterk en makkelijk herkenbaar voor zijn fans.",
    image: "assets/images/cases/mockupManuals.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript", "PHP"],
    image2: "assets/images/reviews/Manuals.webp",
    link: "https://manualsmusic.be/",
    linkFigmaHighFidelityPrototype:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F3HPIJ0oInKkPMe97ekjtF7%2FManuals-website---mobiele-versie%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DhnP5P8daibQTY7Fx-1",
  },
  case6: {
    name: "Showscape",
    intro: "Webdesign voor Showscape.",
    description:
      "Showscape is verantwoordelijk voor het management van enkele grote artiesten uit België, waaronder Michael Schack en Manuals.",
    goal: "De doelstelling van de website van Showscape was om potentiële klanten, zoals artiesten, festivals, en evenementenorganisatoren, te informeren over hun diensten en expertise op het gebied van artiestenmanagement",
    result:
      "Dankzij hun nieuwe website wordt Showscape steeds bekender. Op de site kunnen mensen alles vinden over wat ze doen en welke artiesten ze vertegenwoordigen. Dit helpt hen nog meer op te vallen in de muziekwereld.",
    image: "assets/images/cases/Showscape.webp",
    image2: "assets/images/cases/Showscape2.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript"],
    link: "https://showscape.be/",
  },
  case7: {
    name: "CEO Decorations",
    intro: "Webdesign voor CEO Decorations.",
    description:
      "Ze laten lege flessen van allerlei soorten tot leven komen in unieke kunstwerken. Ik liet hun website tot leven komen.",
    goal: "De website van CEO Decorations laat de unieke kunstwerken van het bedrijf zien en moedigt bezoekers aan om ze te kopen. Ook dient het als inspiratiebron voor mensen die geïnteresseerd zijn in kunst en design.",
    result:
      "Sinds de lancering van de website heeft CEO Decorations aanzienlijk meer naamsbekendheid verworven en een aanzienlijke toename in verkoop gezien, wat heeft bijgedragen aan hun groei en succes in de markt.",
    image: "assets/images/cases/mockupCEODecorations.webp",
    image2: "assets/images/cases/CEODecorations.webp",
    skills: ["HTML", "CSS", "Javascript"],
    link: "https://ceodecorations.be/",
  },
  case8: {
    name: "STUDIO SANOJ",
    intro: "Webdesign voor mijn eigen website.",
    description:
      "Dé plaats waar webdromen werkelijkheid worden, en waar mijn creatieve visie tot leven komt voor een breed publiek.",
    goal: "Mijn website heeft als doel anderen te inspireren door het delen van mijn werk en tegelijkertijd potentiële klanten te overtuigen van mijn vaardigheden en expertise.",
    result:
      "Als resultaat heeft mijn website geleid tot een grotere bekendheid en betrokkenheid van mijn doelgroep, terwijl het ook heeft bijgedragen aan het aantrekken van potentiële klanten en het bevorderen van professionele relaties.",
    image: "assets/images/cases/mockupNewWebsite.webp",
    image2: "assets/images/cases/brandsheet.webp",
    skills: ["Figma", "React", "PHP"],
  },
  case9: {
    name: "Hoppin",
    intro: "Rebranding voor Hoppin, design opdracht voor school",
    description:
      "Hoppin, dat is de mobiliteit van morgen. Met de nieuwe mobiliteitsvisie zet Vlaanderen in op efficiënter, duurzamer en toegankelijker (openbaar) vervoer. Het hoofddoel? Scholen, ziekenhuizen, bedrijfsterreinen, sportcentra, cultuurcentra en winkelcentra vlotter bereikbaar maken voor iedereen. Ook voor wie ouder is, een mobiliteitsbeperking heeft of de wagen liever wat minder gebruikt.",
    goal: "Met deze opdracht werd ik uitgedaagd om in het hoofd van mijn gebruiker te kruipen en hem als uitgangspunt te leren nemen voor het design van mijn digitaal product.",
    image: "assets/images/cases/mockupHoppin.webp",
    image2: "assets/images/cases/Hoppin.webp",
    linkFigmaWireframes:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FvzNIEsv2iw3vokYAqvx6gV%2FHoppin---Jonas-De-Bruyne%3Ftype%3Ddesign%26node-id%3D194%253A1990%26mode%3Ddesign%26t%3Dt27KKsWpNS5WiULB-1",
    linkFigmaMediumFidelityPrototype:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FvzNIEsv2iw3vokYAqvx6gV%2FHoppin---Jonas-De-Bruyne%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3Dt27KKsWpNS5WiULB-1",
    linkFigmaHighFidelityPrototype:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FvzNIEsv2iw3vokYAqvx6gV%2FHoppin---Jonas-De-Bruyne%3Ftype%3Ddesign%26node-id%3D447%253A8784%26mode%3Ddesign%26t%3Dt27KKsWpNS5WiULB-1",
    skills: ["Figma"],
  },
  case10: {
    name: "Little Sun",
    intro:
      "Een innovatief platform voor Little Sun, mede dankzij Stijn Houwelijckx en Thibo Van Ingelgem",
    description:
      "Het platform voor het Little Sun-project in Zambia stroomlijnt het werk op hublocaties door managers in staat te stellen nauwkeurige werkschema's te creëren en taken efficiënt toe te wijzen aan werknemers, terwijl het ook werknemers in staat stelt om gemakkelijk hun vakantie en ziekteperioden in te voeren, waardoor managers een volledig overzicht hebben van de personeelsbeschikbaarheid en gedetailleerde rapporten kunnen bekijken voor inzicht in prestaties en voortgang.",
    goal: "Het doel van het platform is om de effectiviteit van Little Sun te vergroten door werkzaamheden op hun hublocaties te optimaliseren, met als focus het verhogen van efficiëntie, verbeteren van communicatie tussen managers en werknemers, en het verhogen van de algehele productiviteit door duidelijke taaktoewijzing en planning.",
    image: "assets/images/cases/mockupLittleSun.webp",
    image2: "assets/images/cases/LittleSun.webp",
    skills: ["HTML", "CSS", "Javascript", "PHP"],
  },
  case11: {
    name: "Poster Memento",
    intro: "Poster film - Memento",
    description:
      "'Some memories are best forgotten'. Leonard is een onderzoeker voor een verzekeringsmaatschappij. Zijn geheugen is ernstig beschadigd na een poging in te grijpen bij de moord op zijn vrouw. Leonard is uit op wraak, maar door z'n slechte geheugen kan hij alleen behoorlijk functioneren door het constant maken van aantekeningen, tatoeages en het maken van polaroid foto's.",
    goal: "Ik werd uitgedaagd om een filmposter te ontwerpen en daarna dynamisch te coderen met beweging.",
    image: "assets/images/cases/mockupPosterMemento.webp",
    image2: "assets/images/cases/posterMemento.webp",
    skills: ["Illustrator", "HTML", "CSS"],
  },
  case12: {
    name: "CSS Zen Garden Re-design",
    intro:
      "Ontdek de grenzeloze creativiteit van webdesign met het herontwerp van CSS Zen Garden.",
    description:
      "Het herontwerp van CSS Zen Garden omarmt de diversiteit van ontwerpbenaderingen door te focussen op zowel esthetiek als functionaliteit, waarbij de laatste trends in design en technologie worden geïntegreerd om een inspirerende en gebruiksvriendelijke ervaring te creëren voor bezoekers.",
    goal: "Ik werd uitgedaagd om een herontwerp van CSS Zen Garden te creëren dat zowel de esthetische als functionele mogelijkheden van CSS viert, met als doel een inspirerende en gebruiksvriendelijke webervaring te bieden die de grenzen van traditioneel webdesign verlegt.",
    image: "assets/images/cases/mockupCSSZenGarden.webp",
    image2: "assets/images/cases/mockupCSSZenGardenOld.webp",
    skills: ["Figma", "CSS"],
  },
  case13: {
    name: "Dynamic typography",
    intro: "Ontdek mijn uitwerking van de opdracht.",
    description:
      "Voor deze opdracht moest ik een webpagina maken waarbij typografie niet alleen als statisch tekstueel element wordt gebruikt, maar als een dynamisch en interactief onderdeel van de gebruikerservaring. Door gebruik te maken van HTML, CSS en JavaScript, creëer je animaties en interactieve elementen die de tekst tot leven brengen. Denk aan tekst die beweegt, van vorm verandert, reageert op muisbewegingen, kliks, of andere gebruikersacties.",
    goal: "Het doel van deze opdracht is om je vaardigheden te verbeteren door typografie op een innovatieve en dynamische manier te gebruiken. Dit helpt je niet alleen om technische vaardigheden in animatie en interactie te ontwikkelen, maar ook om een beter begrip te krijgen van hoe typografie kan bijdragen aan de gebruikerservaring en visuele communicatie. De opdracht stimuleert creativiteit en experimenteerdrang, terwijl je leert hoe je boeiende en responsieve webcontent kunt maken.",
    image: "assets/images/cases/mockupDynamicTypography.webp",
    skills: ["HTML", "CSS"],
  },
  case14: {
    name: "The useless web",
    intro: "Ontdek mijn useless website.",
    description:
      "Voor deze opdracht moest ik een eigen nutteloze website creëren. Deze website moest geen praktisch nut hebben, maar wel verrassend of bizar zijn. Het idee is om bezoekers iets ongewoons te bieden dat hen even doet glimlachen of verbazen. Denk aan interacties, grappige animaties, rare concepten, of vreemde functionaliteiten.",
    goal: "Het doel van deze opdracht was om mijn creativiteit te stimuleren en mijn vaardigheden op een leuke en onconventionele manier te oefenen. Het project biedt de kans om te experimenteren met HTML, CSS en JavaScript zonder de druk van een functioneel eindproduct. Bovendien leerde het me om buiten de gebaande paden te denken en originele ideeën te bedenken.",
    image: "assets/images/cases/mockupUselessWeb.webp",
    skills: ["HTML", "CSS", "Javascript"],
  },
  case15: {
    name: "SANOJ AUTO'S website",
    intro: "Development opdracht voor school",
    description: "Kom uw rijplezier kiezen.",
    goal: "Deze opdracht stelde me voor de uitdaging om een website te ontwikkelen met behulp van Twig voor templating, met Craft CMS als CMS-systeem.",
    image: "assets/images/cases/mockupSANOJ-AUTOSWebsite.webp",
    image2: "assets/images/cases/BMW2.webp",
    skills: ["Twig", "CMS"],
  },
  case16: {
    name: "SANOJ AUTO'S app",
    intro: "Development opdracht voor school",
    description: "Kom uw rijplezier kiezen.",
    goal: "Deze opdracht stelde me voor de uitdaging om een applicatie te ontwikkelen met behulp van React Native en met Craft CMS als CMS-systeem.",
    image: "assets/images/cases/mockupSANOJ-AUTOSApp.webp",
    image2: "assets/images/cases/BMW2.webp",
    skills: ["React Native", "CMS"],
  },
  case17: {
    name: "Challenge 1 - Speech",
    intro: "Interaction development opdracht voor school",
    description:
      "De opdracht is om een mini quiz web-app te bouwen die spraakherkenning en spraaksynthese gebruikt om vragen voor te lezen en antwoorden te herkennen.",
    goal: "Het doel is om als ontwikkelaar ervaring op te doen met moderne webtechnologieën, interactieve gebruikerservaringen te ontwerpen, en het project te beheren en te presenteren via platforms zoals GitHub.",
    image: "assets/images/cases/mockupchallenge1.webp",
    link: "https://jonasdebruyne.com/Development4/challenges/challenge1",
  },
  case18: {
    name: "Challenge 2 - Body Tracking",
    intro: "Interaction development opdracht voor school",
    description:
      "De opdracht is om een interactieve mini-game te bouwen waarin gebruikers met objecten op het scherm kunnen interageren via poseNet, handpose, facemesh of een combinatie daarvan, met behulp van p5.js.",
    goal: "Het doel is om als ontwikkelaar ervaring op te doen met pose-detectietechnologieën en p5.js, interactieve gebruikerservaringen te ontwerpen, en het project te beheren en te presenteren via platforms zoals GitHub met een online demo.",
    image: "assets/images/cases/mockupchallenge2.webp",
    link: "https://jonasdebruyne.com/Development4/challenges/challenge2",
  },
  case19: {
    name: "Challenge 3 - Classification",
    intro: "Interaction development opdracht voor school",
    description:
      "De opdracht is om een mini-app te bouwen waarmee je een foto kunt maken en objecten kunt herkennen via MobileNet, waarbij je eventueel p5.js of andere libraries zoals webcam-easy gebruikt om de camera aan te spreken.",
    goal: "Het doel is om als ontwikkelaar ervaring op te doen met beeldherkenningstechnologieën en camera-integratie, terwijl je een functionele en gebruiksvriendelijke applicatie ontwerpt en presenteert.",
    image: "assets/images/cases/mockupchallenge3.webp",
    link: "https://jonasdebruyne.com/Development4/challenges/challenge3",
  },
  case20: {
    name: "Challenge 4 - Device Orientation / Motion",
    intro: "Interaction development opdracht voor school",
    description:
      "De opdracht is om een interactief webproject, bijvoorbeeld een mini-game, te bouwen dat gebruik maakt van device orientation en/of motion.",
    goal: "Het doel is om als ontwikkelaar ervaring op te doen met device orientation en motion API's, en een eenvoudige maar leuke en interactieve gebruikerservaring te creëren en te presenteren.",
    image: "assets/images/cases/mockupchallenge4.webp",
    link: "https://jonasdebruyne.com/Development4/challenges/challenge4",
  },
  case21: {
    name: "Challenge 5 - Geolocation",
    intro: "Interaction development opdracht voor school",
    description:
      "De opdracht is om een interactieve applicatie te bouwen die gebruik maakt van de geolocation API en Leaflet om locaties op een kaart weer te geven, waarbij gebruikers bij het bereiken van een locatie een opdracht, vraag, punt, collectable of challenge krijgen.",
    goal: "Het doel is om als ontwikkelaar ervaring op te doen met geolocatie en kaartfunctionaliteiten van Leaflet, en een dynamische en interactieve gebruikerservaring te ontwerpen en te presenteren.",
    image: "assets/images/cases/mockupchallenge5.webp",
    link: "https://jonasdebruyne.com/Development4/challenges/challenge5",
  },
  case22: {
    name: "The whistle",
    intro: "Design opdracht voor school",
    description:
      "Een must voor elke scheidsrechter. Hou je persoonlijke statistieken bij, werk aan je doelen en word een echte professional!",
    goal: "Deze opdracht stelde me voor de uitdaging om een superhelden app te maken, met de bijhorende website.",
    image: "assets/images/cases/mockupTheWhistle.webp",
    image2: "assets/images/cases/Bram.webp_large",
    skills: ["Figma"],
    linkFigmaHighFidelityPrototype:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fn7VOCvujjEDJadpTHVShZK%2FHigh-fi-prototype-app---The-whistle---Jonas-De-Bruyne%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DhnP5P8daibQTY7Fx-1",
  },
  case23: {
    name: "Cheapcart",
    intro: "Opdracht voor school",
    description:
      "Bespaar tijd en geld met Cheapcart, de innovatieve app die je helpt je boodschappenlijst samen te stellen en de beste deals en kortingen te vinden. Ontdek voordelige producten en winkels, maak bewuste keuzes en ervaar financiële rust met Cheapcart. De gebruiksvriendelijke app die je altijd op de hoogte houdt van de beste prijzen voor een stressvrije boodschappenervaring.",
    goal: "Deze opdracht stelde me voor de uitdaging om een applicatie, oplossing te bieden op een probleem binnen het thema 'sustainability'.",
    image: "assets/images/cases/Cheapcart.webp",
    image2: "assets/images/cases/boodschappen.webp",
    skills: ["Figma", "HTML", "CSS", "Javascript", "PHP"],
    linkFigmaWireframes:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeR4wJQR9NkK72HM0c4X6CL%2FWireframes---Cheapcart---Jonas-De-Bruyne%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DhnP5P8daibQTY7Fx-1",
    linkFigmaHighFidelityPrototype:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fapivkp6GeGdXIp81xynn5z%2FLow-fi---Cheapcart---Jonas-De-Bruyne%3Ftype%3Ddesign%26mode%3Ddesign%26t%3DhnP5P8daibQTY7Fx-1",
  },
  case24: {
    name: "XDtravaganza",
    intro: "Een feestje voor de community van mijn opleiding",
    description:
      "XDtravaganza was een feestje om te werken aan de community van de opleiding 'Digital Experience Design'.",
    goal: "De doelstelling was om een feestelijke gelegenheid te creëren die niet alleen plezier bood, maar ook de community van de opleiding samenbracht.",
    image: "assets/images/cases/mockupXDtravaganza.webp",
    image2: "assets/images/cases/XDtravaganza.webp",
    skills: ["HTML", "CSS", "Javascript"],
  },
  case25: {
    name: "XD Quizmas",
    intro: "Een quiz om te werken aan de community van mijn opleiding",
    description:
      "XD Quizmas was een quiz om te werken aan de community van de opleiding 'Digital Experience Design'. De inkomsten van het evenement gingen naar de warmste week",
    goal: "De doelstelling was om via een quiz-evenement de gemeenschap van de opleiding te versterken, terwijl de inkomsten van het evenement werden ingezet ter ondersteuning van de Warmste Week.",
    image: "assets/images/cases/mockupXDQuizmas.webp",
    image2: "assets/images/cases/deWarmsteWeek.webp",
    skills: ["Soft skills"],
  },
};

export const reviews = {
  h2: "Wat klanten zeggen over de samenwerking",
  review1: {
    name: "Joris Van Rossem",
    company: "Metejoor",
    description:
      "Ik ben ontzettend dankbaar voor de samenwerking met STUDIO SANOJ. Samen hebben we een website gecreëerd die perfect mijn passie voor muziek weergeeft. Jonas heeft met zijn creativiteit en toewijding mijn ideeën werkelijkheid laten worden, en ik ben echt onder de indruk van het resultaat. Het dynamische en flexibele ontwerp is precies wat ik zocht. Dank je wel voor je professionaliteit, en ik kijk uit naar wat nog komen gaat.",
    img: "assets/images/reviews/Metejoor.webp",
  },
  review2: {
    name: "Manu Van Aalst",
    company: "Manuals",
    description:
      "Super fijn om mee samen te werken. Een van zijn eerste projecten samen mogen opzetten. Heel gedreven, snelle service. Communicatie ging ook heel vlot. zeker een aanrader.",
    img: "assets/images/reviews/Manuals.webp",
  },
  review3: {
    name: "Tim Audenaert",
    company: "CEO Decorations",
    description:
      "Recentelijk heb ik het genoegen gehad om samen te werken met Studio Sanoj. De samenwerking was buitengewoon plezierig en productief. De website heeft een verbluffende upgrade gekregen, wat de gebruikerservaring aanzienlijk heeft verbeterd. Wat me echt opviel, was de uitstekende communicatie gedurende het hele proces. Hij was altijd snel en duidelijk in zijn antwoorden, waardoor het hele project soepel verliep. Kortom, een geweldige ervaring en een resultaat waar we trots op kunnen zijn. Aanbevolen!",
    img: "assets/images/reviews/Tim.webp",
  },
};

export const FAQ = {
  h2: "Veelgestelde vragen over Webdesign en Creatieve Ontwikkeling",
  question1: {
    question: "Hoeveel kost een website?",
    answer:
      "De prijs van jouw website hangt van enorm veel factoren af. Hoe uitgebreid wil je je website? Heb je specifieke behoeften qua functionaliteiten? Tijdens een intakegesprek stel ik doelgerichte vragen en geef ik advies over welk type webdesign het meest geschikt is voor jouw specifieke behoeften en doelstellingen, zodat je optimale resultaten behaalt.",
  },
  question2: {
    question: "Hoe lang duurt het om een website te bouwen?",
    answer:
      "Bij aanvang van je project stellen we een deadline vast voor de lancering van de website. Over het algemeen lever ik een website binnen zes weken op. Heb je een snellere oplevering nodig? Indien mijn planning dit toelaat, ben ik bereid om flexibel te zijn, zodat we de strategieën kunnen toepassen die nodig zijn om jouw visie werkelijkheid te maken.",
  },
  question3: {
    question: "Wat moet ik doen als jullie mijn website maken?",
    answer:
      "De boodschap is eenvoudig: leun achterover en ontspan. Ik neem letterlijk alles voor je uit handen. Je hoeft geen enkel woord zelf te schrijven en je hoeft je geen zorgen te maken over het bouwen van je website. Het enige wat je hoeft te doen, is de benodigde afbeeldingen aanleveren (of indien gewenst kan ik zelf de nodige foto's maken). Natuurlijk vraag ik regelmatig om feedback, zodat het webdesign volledig aan je wensen voldoet en de impact heeft die je als artiest zoekt.",
  },
  question4: {
    question: "Wat krijg ik allemaal?",
    answer:
      "In het standaard webdesignpakket is het volgende inbegrepen: • Ontwerp van een aantrekkelijke, gebruiksvriendelijke en responsieve website. • Schrijven van de webteksten met een focus op creativiteit en strategie. • Regelmatige feedbacksessies. • Optioneel: Hosting & domeinnaam (inclusief veilig SSL-certificaat). • Optimalisatie voor betere vindbaarheid in Google, oftewel SEO, wat essentieel is voor artiesten die hun bereik willen vergroten. • Snelheidsoptimalisaties voor een verbeterde gebruikerservaring van je klant.",
  },
  question5: {
    question: "Wat houdt de hosting in?",
    answer:
      "Om te beginnen heb je jouw eigen domeinnaam (www.jebedrijf.be). Ik reserveer dit speciaal voor jou op het internet. Daarnaast heb je een hostingpakket, wat eigenlijk jouw plek op het internet is om je website te plaatsen. Ten derde, dankzij mijn partner, kan ik snelle servers garanderen, zodat jouw site altijd snel en soepel laadt, wat cruciaal is voor een succesvolle online aanwezigheid.",
  },
};

export const consultation = {
  h2: "Samen aan jouw webdesign project beginnen?",
  description:
    "Ik ben ontzettend enthousiast om jou als artiest te ondersteunen bij het overwinnen van jouw digitale obstakels. Laten we samen de koppen bij elkaar steken en bespreken op welke creatieve strategieën ik jou kan assisteren in het ontwikkeling van jouw website.",
  btnText: "Start jouw project",
};

export const proces = {
  h2: "Hoe ga ik te werk in jouw webdesign project?",
  step1: {
    image: "assets/images/account-edit.svg",
    name: "1. Intakegesprek",
    description:
      "Via een uitgebreid intakegesprek leer ik jou als artiest en manager beter kennen. Ik kom te weten wat je verwachtingen zijn en wat je wel of niet wil zien. Op die manier kan ik een gepast strategisch advies formuleren voor jouw nieuwe website, zodat deze jouw doelen effectief ondersteunt. Je krijgt een vrijblijvende offerte opgestuurd.",
    methods: ["Empathize", "Define"],
  },
  step2: {
    image: "assets/images/laptop.svg",
    name: "2. Ik maak het design",
    description:
      "Wanneer ik alle beelden heb ontvangen, begin ik met het creëren van een eerste design. Spannend! Je krijgt als het ware een prototype van je webdesign te zien, zonder dat de website al echt online staat. Dit geeft veel ruimte om jouw feedback goed te verwerken.",
    methods: ["Ideate"],
  },
  step3: {
    image: "assets/images/chat-processing-outline.svg",
    name: "3. Tussentijdse feedback",
    description:
      "Tijdens het creatieproces van je site is er alle ruimte voor feedback. Ik voorzie twee feedbackrondes. Je zit zelf mee aan de knoppen en bepaalt mee hoe het eindresultaat er zal uitzien. Nu is het moment om gericht feedback te geven op het design. Wanneer dit design afgeklopt is, start ik met het bouwen van de website.",
    methods: ["Test"],
  },
  step4: {
    image: "assets/images/laptop.svg",
    name: "4. Website gaat in development",
    description:
      "Zodra het prototype vastligt, ga ik verder met het bouwen van de liveversie in een privé-omgeving die alleen jij als klant kunt zien. Eventueel zet ik een leuke teaser klaar of een 'coming soon'-pagina om nieuwsgierige bezoekers te triggeren.",
    methods: ["Prototype"],
  },
  step5: {
    image: "assets/images/chat-processing-outline.svg",
    name: "5. Nogmaals feedback",
    description:
      "Wanneer de website gebouwd is, ga ik nog eens uitgebreid door alle pagina’s en testen we de functionaliteiten zodat alles zeker goed werkt. De laatste details worden besproken tijdens maximaal twee feedbackrondes. Hierna komt de finishlijn in zicht en we zijn bijna klaar om bezoekers te ontvangen in je digitale webomgeving.",
    methods: ["Test"],
  },
  step6: {
    image: "assets/images/rocket-launch-outline.svg",
    name: "6. Je website staat live & brengt resultaat op",
    description:
      "Je nieuwe website staat live & wordt door mij voorzien van alle SEO-optimalisaties, zodat je goed gevonden wordt in Google, evenals snelheidsoptimalisaties en veiligheidsinstellingen. Nu kan het echte werk beginnen: leads binnenhalen voor jouw artistieke onderneming!",
    methods: ["Implement"],
  },
  step7: {
    image: "assets/images/chart-line.svg",
    name: "7. After-service & onderhoud",
    description:
      "Per kwartaal rapporteer ik de resultaten. Zo blijf jij altijd op de hoogte van hoe je website presteert! Naast hoogkwalitatieve hosting en zeer sterke, veilige software, geniet je binnen het onderhoudspakketje van alle nodige updates. Je site ligt nooit onverwacht plat. Optioneel geniet je van 3 service-uren waarbij ik kleine aanpassingen kan doen aan je webdesign.",
    methods: ["Implement"],
  },
};

export const introCases = {
  span: "Cases",
  h2: "Benieuwd naar mijn succesverhalen in Webdesign?",
  description:
    "Bekijk hieronder enkele referenties van succesvolle en voltooide websites die ik heb ontwikkeld voor artiesten en creatieve ondernemers. Ontdek hoe mijn strategische aanpak en creativiteit het webdesign naar een hoger niveau tilen.",
};

export const contact = {
  span: "Contact",
  h2: "Ik leer graag meer over jou en je creatieve onderneming",
  description:
    "Neem gerust contact met me op als je vrijblijvend een praatje wilt maken over een webdesignconcept of als je vragen hebt over hoe ik jou kan helpen in de ontwikkeling van jouw online aanwezigheid. Ik zal zo snel mogelijk antwoorden!",
};
