import React, { useEffect } from "react";
import "./Normalize.css";
import "./App.css";
import Home from "./components/pages/Home";
import Footer from "./components/pages/Footer";
import About from "./components/pages/About";
import Projects from "./components/pages/Projects";
import Contact from "./components/pages/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import CaseDetails from "./components/pages/CaseDetails";

function App() {
  useEffect(() => {
    // Injecteer VWO code dynamisch zodra de app laadt
    (function () {
      var vwoScript = document.createElement("script");
      vwoScript.type = "text/javascript";
      vwoScript.async = true;
      vwoScript.id = "vwoCode";
      vwoScript.innerHTML = `
        window._vwo_code||(function(){
          var account_id=970374,version=2.1,settings_tolerance=2000,hide_element="body",
          hide_element_style="opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;transition:none !important;",f=false,w=window,d=document,v=d.querySelector("#vwoCode"),
          cK="_vwo_"+account_id+"_settings",cc={};
          
          try {
            var c = JSON.parse(localStorage.getItem("_vwo_"+account_id+"_config"));
            cc = c && typeof c === "object" ? c : {};
          } catch (e) {}

          var stT=cc.stT==="session" ? w.sessionStorage : w.localStorage;

          var code = {
            nonce: v && v.nonce,
            init: function() {
              if (d.URL.indexOf("__vwo_disable__") > -1) return;
              w._vwo_code = code;
            },
          };
          code.init();
        })();
      `;
      document.head.appendChild(vwoScript);
    })();
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/case/:caseName" element={<CaseDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
