import React from "react";
import Case from "../components/Case";

const Cases = ({ h2, projects }) => {
  const filteredCases = Object.fromEntries(
    Object.entries(projects).filter(([name]) => name !== "h2")
  );
  return (
    <div className="cases-section">
      <h2>{h2}</h2>
      <div className="cases">
        {Object.entries(filteredCases).map(([caseKey, caseData]) => (
          <Case
            key={caseKey}
            name={caseData.name}
            description={caseData.description}
            image={caseData.image}
            skills={caseData.skills}
          />
        ))}
      </div>
    </div>
  );
};

export default Cases;
