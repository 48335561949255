import React from "react";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Case = ({ name, description, image }) => (
  <Link to={`/case/${name}`} onClick={scrollToTop} className="case-link">
    <div className="case">
      <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="text">
        <h3>{name}</h3>
        <p>{description}</p>
        <p className="details">Bekijk details</p>
      </div>
    </div>
  </Link>
);

export default Case;
