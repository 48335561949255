import React from "react";

const ProcesItem = ({ image, name, description, methods }) => (
  <div className="procesItem">
    <div className="column aligItems">
      <div className="icon">
        <div style={{ backgroundImage: `url(${image})` }}></div>
      </div>
      <p className="border"></p>
    </div>
    <div className="column marginBottom">
      <div className="methods">
        {Array.isArray(methods) ? (
          methods.map((method, index) => <span key={index}>{method}</span>)
        ) : (
          <span>No skills available</span>
        )}
      </div>
      <h3>{name}</h3>
      <p>{description}</p>
    </div>
  </div>
);

export default ProcesItem;
