import React, { useState } from "react";

function ContactForm({ span, h2, description }) {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    if (
      formData.firstname.trim() === "" ||
      formData.lastname.trim() === "" ||
      formData.email.trim() === "" ||
      formData.subject.trim() === "" ||
      formData.message.trim() === ""
    ) {
      setIsSuccess(false);
      setIsError(true);
      return; // Stop further execution if validation fails
    }

    if (!validateEmail(formData.email)) {
      setIsSuccess(false);
      setIsError(true);
      return; // Stop further execution if email is invalid
    }

    try {
      const response = await fetch("form-handler.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData),
      });

      console.log(response);

      if (response.ok) {
        setIsSuccess(true);
        setIsError(false);
      } else {
        setIsSuccess(false);
        setIsError(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  // Helper function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <div className="contact-section">
        <span>{span}</span>
        <h2>{h2}</h2>
        <p>{description}</p>
        <form className="contactform" onSubmit={handleSubmit}>
          <div className="row">
            <div className="column">
              <label>Uw voornaam</label>
              <input
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                placeholder="John"
              />
            </div>
            <div className="column">
              <label>Uw familienaam</label>
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                placeholder="Doe"
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Uw e-mailadres</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="JohnDoe@gmail.com"
              />
            </div>
            <div className="column">
              <label>Uw onderwerp</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                placeholder="Intakegesprek"
              />
            </div>
          </div>
          <div className="column">
            <label>Uw bericht</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Ik wil graag een intakegesprek boeken."
            ></textarea>
          </div>
          <button type="submit">Versturen</button>
          {isSuccess && <p className="success">Succesvol verzonden!</p>}
          {isError && (
            <p className="error">Er is iets misgegaan, probeer opnieuw.</p>
          )}
        </form>
      </div>
    </>
  );
}

export default ContactForm;
