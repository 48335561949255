import React from "react";
import { useParams } from "react-router-dom";
import { projects } from "../Data";

function CaseDetails() {
  const { caseName } = useParams();
  const projectKeys = Object.keys(projects);
  const project = projectKeys
    .map((key) => projects[key])
    .find((project) => project.name === caseName);

  if (!project) {
    return <div>Project niet gevonden</div>;
  }

  const { skills } = project;

  return (
    <div className="caseDetails">
      <div className="row alignItems">
        <div className="column">
          <h1>{project.name}</h1>
          <p className="border"></p>
          <p>{project.intro}</p>
          <a className="btn">
            <p>Ontdek meer</p>
            <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
          </a>
        </div>
        <div
          className="caseImage"
          style={{ backgroundImage: `url(../${project.image})` }}
        ></div>
      </div>
      <div className="row">
        <div className="column">
          <div>
            <span>Project</span>
            <h2>Informatie</h2>
          </div>
          <p className="border"></p>
          <div className="skills">
            {Array.isArray(skills)
              ? skills.map((skill, index) => <span key={index}>{skill}</span>)
              : ""}
          </div>
        </div>
        <div className="row">
          <div className="column width">
            <h3>Beschrijving</h3>
            <p>{project.description}</p>
          </div>
          <div className="column width">
            <h3>Doelstelling</h3>
            <p>{project.goal}</p>
          </div>
        </div>
      </div>
      {project.linkFigmaHighFidelityPrototype && (
        <div className="column" id="info">
          <div className="column">
            <div>
              <span>Proces</span>
              <h2>Prototypes</h2>
            </div>
            <p className="border"></p>
          </div>
          <div className="row">
            {project.linkFigmaWireframes && (
              <div className="column width">
                <h3>Wireframes</h3>
                <iframe
                  title="Wireframes"
                  width="100%"
                  height="200"
                  src={project.linkFigmaWireframes}
                  allowFullScreen
                ></iframe>
              </div>
            )}
            {project.linkFigmaMediumFidelityPrototype && (
              <div className="column width">
                <h3>Medium Fidelity Prototype</h3>
                <iframe
                  title="Medium Fidelity Prototype"
                  width="100%"
                  height="200"
                  src={project.linkFigmaMediumFidelityPrototype}
                  allowFullScreen
                ></iframe>{" "}
              </div>
            )}
            {project.linkFigmaHighFidelityPrototype && (
              <div className="column width">
                <h3>High Fidelity Prototype</h3>
                <iframe
                  title="High Fidelity Prototype"
                  width="100%"
                  height="200"
                  src={project.linkFigmaHighFidelityPrototype}
                  allowFullScreen
                ></iframe>{" "}
              </div>
            )}
          </div>
        </div>
      )}
      {project.image2 && (
        <div
          className="caseImage2"
          style={{ backgroundImage: `url(../${project.image2})` }}
        ></div>
      )}
      {project.link && (
        <div className="row">
          <div className="column">
            <div>
              <span>Project</span>
              <h2>Resultaat</h2>
            </div>
            <p className="border"></p>
          </div>
          <div className="column widthBig">
            <p>{project.result}</p>
            {project.link && (
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                <i className="fa fa-link" aria-hidden="true"></i>
                <p>Bezoek de website</p>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CaseDetails;
