import React, { useEffect } from "react";
import ProcesItem from "../components/ProcesItem";

function Proces({ h2, proces }) {
  const filteredItems = proces
    ? Object.fromEntries(
        Object.entries(proces).filter(([name]) => name !== "h2")
      )
    : {};

  useEffect(() => {
    const handleScroll = () => {
      const procesItems = document.querySelectorAll(".procesItem");
      if (procesItems) {
        procesItems.forEach((item, index) => {
          const boundingBox = item.getBoundingClientRect();
          const scrollPercentage =
            ((window.innerHeight - boundingBox.bottom) / boundingBox.height) *
            100;
          const borderColorOpacity = scrollPercentage / 100; // Adjust for opacity
          const bgColorOpacity = scrollPercentage / 100; // Adjust for opacity
          const borderElement = item.querySelector(".border");
          const iconElement = item.querySelector(".icon");

          if (borderElement) {
            borderElement.style.borderColor = `rgb(128, 128, 128, ${borderColorOpacity})`;
            borderElement.style.borderWidth = "1px"; // Set your desired border thickness
          }

          if (iconElement) {
            const bgColor =
              scrollPercentage > 50 ? "var(--green)" : "var(--grey)"; // Set background color or "transparent" to remove it
            iconElement.style.backgroundColor = bgColor;
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // initial call to set initial border color, thickness, and background color
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="proces-section">
      <h2>{h2}</h2>
      <div className="proces">
        {Object.entries(filteredItems).map(([stepName, stepData]) => (
          <ProcesItem
            key={stepName}
            image={stepData.image}
            name={stepData.name}
            description={stepData.description}
            methods={stepData.methods}
          />
        ))}
      </div>
    </div>
  );
}

export default Proces;
