import React from "react";

function ContactForm({ span, h2, description }) {
  return (
    <>
      <div className="intro-section">
        <span>{span}</span>
        <h2>{h2}</h2>
        <p>{description}</p>
      </div>
    </>
  );
}

export default ContactForm;
