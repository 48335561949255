import React from "react";
import { Helmet } from "react-helmet"; // Voeg react-helmet toe
import ContactForm from "../../sections/ContactForm";
import Partners from "../../sections/Partners";
import Reviews from "../../sections/Reviews";
import FAQ from "../../sections/FAQ";
import Consultation from "../../sections/Consultation";
import {
  contact,
  partners,
  reviews,
  FAQ as faqData,
  consultation,
} from "../Data";

function Contact() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://jonasdebruyne.com/contact" />
        <title>STUDIO SANOJ - Contacteer me</title>{" "}
        <meta
          name="description"
          content="Neem contact op met me voor het ontwikkelen van een op maat gemaakte website of app voor jouw artiest."
        />{" "}
      </Helmet>
      <ContactForm {...contact} />
      <Partners h2={partners.h2} images={partners} />
      <Reviews h2={reviews.h2} reviews={reviews} />
      <FAQ h2={faqData.h2} faqData={faqData} />
      <Consultation {...consultation} />
    </>
  );
}

export default Contact;
