import React from "react";

function Partner({ name, image }) {
  return (
    <div
      className={`partner ${name}`}
      style={{ backgroundImage: `url(${image || ""})` }}
    ></div>
  );
}

export default Partner;
