import React from "react";
import Partner from "../components/Partner";

function Partners({ h2, images }) {
  const filteredImages = Object.fromEntries(
    Object.entries(images).filter(([name]) => name !== "h2")
  );

  return (
    <>
      <div className="partners-section">
        <h2>{h2}</h2>
        <div className="partners">
          {Object.entries(filteredImages).map(([partnerName, partnerImage]) => (
            <Partner
              key={partnerName}
              name={partnerName}
              image={partnerImage.image}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Partners;
