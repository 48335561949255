import React from "react";

const Stats = ({ name, amount, icon }) => (
  <div className="stats">
    <div className="row">
      <p className="amount">{amount}</p>
      {icon}
    </div>
    <p>{name}</p>
  </div>
);

export default Stats;
