import React from "react";

function Bullet({ isActive, onClick }) {
  const classNames = `bullet ${isActive ? "active" : ""}`;

  const handleClick = () => {
    onClick();
  };

  return <div className={classNames} onClick={handleClick}></div>;
}

export default Bullet;
