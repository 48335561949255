import React from "react";
import { Helmet } from "react-helmet"; // Voeg react-helmet toe
import IntroCases from "../../sections/IntroCases";
import AllCases from "../../sections/AllCases";
import Reviews from "../../sections/Reviews";
import FAQ from "../../sections/FAQ";
import Consultation from "../../sections/Consultation";
import {
  introCases,
  projects,
  reviews,
  FAQ as faqData,
  consultation,
} from "../Data";

function Projects() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://jonasdebruyne.com/projects" />
        <title>STUDIO SANOJ - Agency Sint-Niklaas</title>{" "}
        <meta
          name="description"
          content="De ideale partner voor jouw artiesten"
        />{" "}
      </Helmet>
      <IntroCases {...introCases} />
      <AllCases h2={projects.h2} projects={projects} />
      <Reviews h2={reviews.h2} reviews={reviews} />
      <FAQ h2={faqData.h2} faqData={faqData} />
      <Consultation {...consultation} />
    </>
  );
}

export default Projects;
