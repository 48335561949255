import React, { useState } from "react";

function Question({ question, answer }) {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  const toggleAnswerVisibility = () => {
    setIsAnswerVisible(!isAnswerVisible);
  };

  const iconSrc = isAnswerVisible
    ? "assets/images/minus-circle-outline.svg"
    : "assets/images/plus-circle-outline.svg";

  return (
    <div className="question">
      <div className="row" onClick={toggleAnswerVisibility}>
        <p>{question}</p>
        <img src={iconSrc} alt="Question icon" />
      </div>
      {isAnswerVisible && <p>{answer}</p>}
    </div>
  );
}

export default Question;
